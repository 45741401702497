define("ember-cli-notifications/components/notification-container", ["exports", "@ember/component", "@ember/object", "@ember/template", "@ember/service", "ember-cli-notifications/templates/components/notification-container"], function (_exports, _component, _object, _template, _service, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NotificationContainerComponent = _exports.default = (_dec = (0, _object.computed)('position'), _dec2 = (0, _object.computed)('zindex'), _class = class NotificationContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "notifications", _descriptor, this);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _notificationContainer.default);
      _defineProperty(this, "position", 'top');
      _defineProperty(this, "zindex", '1060');
    }
    get positionClass() {
      return `ember-cli-notifications-notification__container--${this.position}`;
    }
    get inlineStyle() {
      return (0, _template.htmlSafe)(`z-index: ${this.zindex};`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "positionClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "positionClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inlineStyle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "inlineStyle"), _class.prototype), _class);
});